export const SLOT_TYPES = {
  INTERFACE: 'interface',
  FRYER: 'fryer',
  SHELF: 'shelf',
};

export const OCCUPANCY_STATE = {
  UNKNOWN: 0,
  FREE: 1,
  ENTERING: 2,
  OCCUPIED: 3,
  LEAVING: 4,
  RESERVED: 5,
  MAYBE_ENTERING: 6,
};

export const DISPENSER_STATUS = Object.freeze({
  FULL: 'full',
  EMPTY: 'empty',
  ERROR: 'error',
});

export const PNEUM_CHANNEL = {
  GRIPPER: 1,
  AGITATOR: 2,
};

export const TASK_STATUSES = {
  SCHEDULED: 1,
  PLANNING: 2,
  PLANNED: 3,
  EXECUTING: 4,
  VALIDATING: 5,
};

export const COMMANDS = Object.freeze({
  PAUSE: 'pause',
  RESUME: 'resume',
  JOG: 'jog',
  UNJOG: 'unjog',
  STOP: 'stop',
  MANUAL: 'manual',
  CLEAN1: 'clean1',
  HOME: 'home',
  CLEAN: 'clean',
  DISPENSE: 'dispense',
  PREDUMP: 'pre-dump',
  DUMP: 'dump',
});

export const COMMAND_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  PENDING: 2,
};

// NOTE(NK): Current as of 03/18/2019
export const GOGO_ROBO_ERROR = {
  SUCCESS: 0,
  INVALID_TOOL_TYPE: 1,
  INVALID_BASE_FRAME: 2,
  INVALID_TOOL_FRAME: 3,
  JOINT_NAME_POSITION_SIZE_MISMATCH: 4,
  JOINT_NAME_VELOCITY_SIZE_MISMATCH: 5,
  JOINT_NAME_EFFORT_SIZE_MISMATCH: 6,
  INVALID_NUMBER_OF_JOINTS: 7,
  INVALID_JOINT_NAMES: 8,
  INVALID_ANGULAR_SPEED: 9,
  INVALID_LINEAR_SPEED: 10,
  INVALID_INTERPOLATION_METHOD: 11,
  INVALID_LINEAR_ACCELERATION: 12,
  INVALID_LINEAR_DECELERATION: 13,

  SEND_TRAJECTORY_FAILED: 100,
  SEND_PNEUMATICS_FAILED: 101,
  SEND_PNEUMATICS_PULSE_TRAIN_FAILED: 102,

  FAILED_TO_GET_CURRENT_JOINTS: 200,
  FAILED_TO_GET_CURRENT_STATE: 201,
  FAILED_TO_GET_CURRENT_TOOL_INFO: 202,
  JOINT_STATES_NOT_RECEIVED: 203,

  FAILED_TO_UPDATE_PLANNING_SCENE: 300,
  INVALID_ALLOWED_COLLISIONS: 301,

  JOINT_SPEED_SIZE_MISMATCH: 1000,
  JOINT_SPEED_TOO_LARGE: 1001,
  JOINT_SPEED_TOO_SMALL: 1002,
  JOINT_SPEED_NEGATIVE: 1003,

  JOINT_POSITION_LIMITS_EXCEEDED: 2000,
  IN_COLLISION: 2001,
  NO_COLLISION_CHECKER: 2002,
  COLLISION_CHECK_JOINT_NAMES_INVALID: 2003,

  // RBDL errors
  INVALID_BODY_ID: 3000,
  INVERSE_KINEMATICS_FAILED: 3001,

  // Behavior errors
  INVALID_WORKSPACE: 4000,
  FAILED_TO_GET_BEHAVIOR_LOCK: 4001,
  MOTION_PLAN_NOT_IN_CACHE: 4002,
  BASKET_TOO_HIGH_TO_SHAKE: 4003,
  BASKET_TOO_HIGH_TO_HANG: 4004,
  BASKET_TOO_LOW_TO_REMOVE: 4005,

  // Calibration
  NO_CAMERAS_REQUESTED: 5000,
  CAL_TOOL_NOT_EQUIPPED: 5001,
  FAILED_TO_RESET_JOINTS: 5002,
  FAILED_TO_CHANGE_CAMERA_SETTINGS: 5003,
  CALIBRATION_CANCELLED: 5004,

  // MPC errors
  PYTHON_ERROR: 6000,
  SOLVER_ERROR: 6001,

  // Alarm errors
  ALARM_LOG_FAILED: 7000,
  ALARM_LOOKUP_FAILED: 7001,
  ALARM_INACTIVE: 7002,
  ALARM_CLEAR_FAILED: 7003,

  // Environment State Machine
  INVALID_FOOD_TYPE: 10000,
  INVALID_BASKET_ID: 10001,

  // This represents a suspected bug in the system.
  UNEXPECTED_CONDITION: 9999,
};

// Task status enum for an executing task.
export const EXECUTING_STATUS = 4;

export const EXISTING_FOOD = {
  baked_wings: 'ChickenWings',
  boneless_chicken_wings: 'ChickenBites',
  boneless_wings: 'ChickenBites',
  breaded_cauliflower: 'CauliflowerBites',
  breaded_chicken_breasts: 'ChickenTenders',
  breaded_pickle: 'PickleRounds',
  buffalo_boneless_wings: 'ChickenBites',
  cauliflower_wings: 'CauliflowerBites',
  cheddar_bites: 'CheeseBites',
  cheese_curds: 'CheeseBites',
  cheese_rings: 'CheeseRings',
  cheese_sticks: 'CheeseSticks',
  chicken_bites: 'ChickenBites',
  chicken_nuggets: 'ChickenBites',
  chicken_patties: 'ChickenPatty',
  chicken_rings: 'ChickenRings',
  chicken_sandwich: 'ChickenPatty',
  chicken_strips: 'ChickenTenders',
  chicken_tender: 'ChickenTenders',
  chicken_tenders: 'ChickenTenders',
  chips: 'TortillaChips',
  churros: 'Churros',
  crispy_cauliflower_wings: 'CauliflowerBites',
  crispy_chicken: 'ChickenWings',
  crispy_chicken_fillets: 'ChickenFilet',
  crispy_chicken_wings: 'CrispyChickenWings',
  crinkle_fries: 'PotatoCrinkleFries',
  crisscut_fries: 'PotatoWaffle',
  curly_fries: 'PotatoCurlyFries',
  egg_rolls: 'EggRolls',
  fish_and_chips: 'Fish',
  fish_fillets: 'FishPatty',
  fish_nibblers: 'FishBites',
  french_fries: 'FrenchFries',
  french_toast_sticks: 'FrenchToastSticks',
  fried_pickles: 'PickleRounds',
  fried_white_chedder_bites: 'CheeseBites',
  hand_breaded_fillets: 'ChickenFilet',
  hand_breaded_tenders: 'ChickenTenders',
  handbreaded_chicken_fillets: 'ChickenFilet',
  handbreaded_chicken_tenders: 'ChickenTenders',
  hash_rounds: 'PotatoRounds',
  hash_round_nibblers: 'PotatoRounds',
  hashbrowns: 'PotatoesHashedBrowns',
  jalapeno_poppers: 'JalapenosStuffed',
  lime: 'Lime',
  mac_and_cheese_nibblers: 'MacaroniBites',
  monster_tacos: 'TacosLarge',
  mozzarella_sticks: 'CheeseSticks',
  oil: 'Oil',
  onion_chips: 'OnionChips',
  onion_rings: 'OnionRings',
  panko_fish: 'FishPatty',
  pickles: 'PickleRounds',
  popcorn_chicken: 'ChickenPopcorn',
  potato_puffs: 'PotatoBites',
  potato_wedges: 'PotatoWedges',
  pumpkin_croissant_bites: 'PumpkinCroissantBites',
  raw_traditional_wings: 'ChickenWings',
  regular_chickens: 'ChickenFilet',
  salt: 'Salt',
  seafood_crab_cake_sliders: 'CrabPatty',
  shrimp_nibblers: 'ShrimpBites',
  sriracha_shrimp_nibblers: 'ShrimpBitesSpicy',
  spicy_chicken_cutlets: 'ChickenFiletSpicy',
  spicy_chicken_fillets: 'ChickenFiletSpicy',
  spicy_chickens: 'ChickenFiletSpicy',
  spicy_tenders: 'ChickenTendersSpicy',
  stuffed_jalapenos: 'JalapenosStuffed',
  sweet_potato_waffle_fries: 'PotatoWaffle',
  tacos: 'TacosRegular',
  tater_tots: 'PotatoBites',
  tiny_tacos: 'TacosMini',
  traditional_wings: 'ChickenWings',
  unknown_item: 'UnknownItem',
};

// The Enum used to determine whether we're in manual mode.
export const JOG_MANUAL_MODE = 2;

export const HOPPER_OPTIONS = ['S', 'M', 'L'];

export const RECIPE_STATES = {
  PREPARING: 'Preparing',
  DIPPING: 'Dipping',
  SUBMERGED: 'Submerged',
  DIPPED: 'Dipped',
  HANGING: 'Hanging',
  UNSUBMERGED: 'Unsubmerged',
  HUNG: 'Hung',
  REMOVING: 'Removing',
  REMOVED: 'Removed',
};

export const DIAGNOSTICS_NODES = [
  '/alarm_logger',
  '/alarm_node',
  '/ar_localizer',
  '/auto_bin_food_detector',
  '/auto_bin_controller_node',
  '/calibration_master',
  '/cameras',
  '/camera_settings_manager',
  '/realsense2_camera_manager',
  '/check_elements_visible',
  '/data_dump_watchdog',
  '/datadog_logger',
  '/flippy_alive',
  '/fryer_behavior_server',
  '/go_to_named_joints',
  '/gogo_robo_node',
  '/io_relay',
  '/joint_state_filter',
  '/joint_trajectory_action',
  '/motion_streaming_interface',
  '/motoman_adapter',
  '/network_dev_monitor',
  '/object_diagnostics',
  '/offset_updater',
  '/relay_controller_node',
  '/rosbag_manager',
  '/safety_controller_node',
  '/simple_chef',
  '/simple_chef_logger',
  '/simple_dispenser',
  '/teknic_sc_motor_driver_node',
  '/tool_manager',
  '/trajectory_manager',
];

export const OFFSET_DIRECTIONS = ['X', 'Y', 'Z'];

// Note: MSC should only be using the support level permission.
export const DISABLING_LEVEL = {
  UI_USER: 'ui_user', // Standard user input.
  UI_ELEVATED: 'ui_elevated', // ChefUI manager-level admin
  FLIPPY: 'flippy', // Automated Flippy actions
  SUPPORT: 'support', // MSC or ChefUI technician-level admin
};

export const TARGET_STATE = Object.freeze({
  COOKING: 'cooking',
  SYSTEM_ACCESS: 'system_access',
  EMERGENCY: 'emergency',
  RECOVERY: 'recovery',
  PRE_COOK_CHECK: 'pre_cook_check',
  JOGGING: 'jogging',
});

export const URGENCY_LEVEL = Object.freeze({
  ASAP: 'asap', // Immediately, interrupting current behavior.
  AFTER_BEHAVIOR: 'after_behavior', // After current behavior is finished.
  WHEN_AVAILABLE: 'when_available', // Once the robot has no other tasks (cooking finished).
});

export const ALARM_ID = {
  COMMUNICATION_ALARM: 0,
  COLLISION_ALARM: 1,
  GENERAL_ALARM: 2,
  UNKNOWN_ALARM: 3,
  E_STOPPED: 4,
  MOTION_HOLD: 5,
  SOFTWARE_MOTION_STOP: 6,
};

export const ROBOT_ARM_STATE = {
  DISABLED: 0,
  MANUAL: 1,
};

export const ALARM_MESSAGE_FILTERED = [
  ALARM_ID.COMMUNICATION_ALARM,
  ALARM_ID.COLLISION_ALARM,
  ALARM_ID.GENERAL_ALARM,
  ALARM_ID.UNKNOWN_ALARM,
  ALARM_ID.MOTION_HOLD,
];

export const POWER_STATE = {
  POWERING_OFF: 'Powering Off',
  POWERING_ON: 'Powering On',
  RUNNING_BIT_FOR: 'Running BIT for',
};

export const DEFAULT_NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

export const SERIALS = {
  FLIPPY3_QA: 'F3-Test',
  FLIPPY3_PROD: 'Flippy3',
};

export const HOPPER_ERROR_PROPERTIES = {
  TITLE: 'title',
  RESOLUTION_STEPS: 'resolution_steps',
  SLOT: 'slot',
  ERROR_CODE: 'error_code',
  SECS: 'secs',
  NSECS: 'nsecs',
};

export const DIAGNOSTICS_STATUS = {
  OK: 0,
  WARN: 1,
  ERROR: 2,
  STALE: 3,
  UNKNOWN: 255,
};

export const BEHAVIOR_NAME = {
  SOURCE: 'source',
  TARGET: 'target',
};

export const ELEVATOR_COMMANDS = {
  DUMP: 'dump',
  RECEIVE_FOOD: 'receive-food',
  HOME: 'home',
};
