var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ContainerIterator = /** @class */ (function () {
    /**
     * @internal
     */
    function ContainerIterator(iteratorType) {
        if (iteratorType === void 0) { iteratorType = 0 /* IteratorType.NORMAL */; }
        this.iteratorType = iteratorType;
    }
    /**
     * @param iter - The other iterator you want to compare.
     * @returns Whether this equals to obj.
     * @example
     * container.find(1).equals(container.end());
     */
    ContainerIterator.prototype.equals = function (iter) {
        return this._node === iter._node;
    };
    return ContainerIterator;
}());
export { ContainerIterator };
var Base = /** @class */ (function () {
    function Base() {
        /**
         * @description Container's size.
         * @internal
         */
        this._length = 0;
    }
    Object.defineProperty(Base.prototype, "length", {
        /**
         * @returns The size of the container.
         * @example
         * const container = new Vector([1, 2]);
         * console.log(container.length); // 2
         */
        get: function () {
            return this._length;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @returns The size of the container.
     * @example
     * const container = new Vector([1, 2]);
     * console.log(container.size()); // 2
     */
    Base.prototype.size = function () {
        return this._length;
    };
    /**
     * @returns Whether the container is empty.
     * @example
     * container.clear();
     * console.log(container.empty());  // true
     */
    Base.prototype.empty = function () {
        return this._length === 0;
    };
    return Base;
}());
export { Base };
var Container = /** @class */ (function (_super) {
    __extends(Container, _super);
    function Container() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Container;
}(Base));
export { Container };
