import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';

import {Button, Typography} from '@material-ui/core';

import DraggableDialog from '../components/DraggableDialog';
import MisoDialogTitle from '../components/MisoDialogTitle';
import {
  abortTrajectory,
  abortBehavior,
  runFryerPoseCalibration,
  setTargetMode,
} from '../ros_init.js';
import {callService} from '../util';
import {DISABLING_LEVEL, TARGET_STATE, URGENCY_LEVEL} from '../enums.js';

class FryerPoseCalibrationModal extends PureComponent {
  constructor() {
    super();
    this.initialState = {
      calibrating: false,
      calibratingSuccess: false,
      calibratingFailed: null,
    };
    this.state = this.initialState;
  }

  handleOnClick = async () => {
    const abort_request = {};
    const jog_request = {
      user: {
        permissions: DISABLING_LEVEL.SUPPORT,
        name: this.props.user,
      },
      mode: TARGET_STATE.JOGGING,
      urgency: URGENCY_LEVEL.ASAP,
      pose: '',
      reason: 'support_jogging',
    };
    await callService(
      setTargetMode,
      this.props.instance,
      jog_request,
      'Set Manual Mode',
      null,
      async () => {
        await callService(
          abortBehavior,
          this.props.instance,
          abort_request,
          'Abort Robot Behavior',
          '',
          '',
          async () => {
            await callService(
              abortTrajectory,
              this.props.instance,
              abort_request,
              'Abort Robot Trajectory'
            );
          }
        );
      }
    );
    const request = {
      behavior_name: 'run_fryer_pose_calibration',
      pickup: '',
      dropoff: '',
      pickup_2: '',
      dropoff_2: '',
      end_joints: 'fpc_p1_x',
      rebuild_behavior_cache: true,
    };
    this.setState({calibrating: true});
    this.setState({calibratingFailed: false});
    const allCallback = () => {
      this.setState({calibrating: false});
    };
    const successCallback = () => {
      this.setState({calibrating: false});
      this.setState({calibratingSuccess: true});
      setTimeout(() => {
        this.setState({calibratingSuccess: false});
      }, 1500);
    };
    const errorCallback = () => {
      this.setState({calibrating: false});
      this.setState({calibratingFailed: true});
      setTimeout(() => {
        this.setState({calibratingFailed: null});
      }, 1500);
    };
    callService(
      runFryerPoseCalibration,
      this.props.instance,
      request,
      'Fryer Pose Calibration',
      allCallback,
      successCallback,
      errorCallback
    );
  };

  render() {
    const {classes, open, onClose} = this.props;
    const {calibrating, calibratingFailed, calibratingSuccess} = this.state;

    return (
      <div>
        <DraggableDialog
          open={open}
          onClose={onClose}
          aria-labelledby="jog-confirmation-dialog"
          fullWidth={true}
          maxWidth="md"
        >
          <MisoDialogTitle
            id="jog-confirmation-dialog"
            onClose={onClose}
            style={{background: 'grey'}}
          >
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'left',
                fontWeight: 'bold',
                fontSize: '2rem',
              }}
            >
              <span>Pose Calibration</span>
            </div>
          </MisoDialogTitle>
          <p
            style={{
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              color: '#FC648F',
              fontWeight: 'bold',
              fontSize: '2rem',
              background: '#FCD2DE',
              border: '0.125rem solid #FC648F',
              borderRadius: '0.5rem',
              margin: '0 2rem',
            }}
          >
            Please ensure there are NO BASKETS, SHELVES or objects inside the
            barrier
          </p>
          {!calibrating && (
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                padding: '2rem 0',
              }}
            >
              Do you want to continue with Pose Calibration?
            </p>
          )}
          {!calibrating ? (
            <div className={classes.buttonContainerConfirmation}>
              <Button
                className={classes.button}
                onClick={this.handleOnClick}
                style={{background: '#525057'}}
              >
                <Typography variant="h5" style={{color: 'white'}}>
                  Continue
                </Typography>
              </Button>
              <Button className={classes.button} onClick={onClose}>
                <Typography variant="h5" style={{color: '#5C5C5C'}}>
                  Cancel
                </Typography>
              </Button>
            </div>
          ) : (
            <div className={classes.buttonContainerConfirmation}>
              <Button
                className={classes.button}
                style={{background: '#E7E9EC'}}
                disabled={true}
              >
                <Typography variant="h5" style={{color: '#5C5C5C'}}>
                  Pose Calibration in Progress
                </Typography>
              </Button>
            </div>
          )}
        </DraggableDialog>
      </div>
    );
  }
}

FryerPoseCalibrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonContainer: {
    width: '15rem',
    height: '15rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  buttonContainerConfirmation: {
    float: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    borderRadius: '0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
  button: {
    margin: '1em',
    width: '90%',
    boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
  },
});

export default withStyles(styles)(withRouter(FryerPoseCalibrationModal));
