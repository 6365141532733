var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ContainerIterator } from "../../ContainerBase";
import { throwIteratorAccessError } from "../../../utils/throwError";
var TreeIterator = /** @class */ (function (_super) {
    __extends(TreeIterator, _super);
    /**
     * @internal
     */
    function TreeIterator(node, header, iteratorType) {
        var _this = _super.call(this, iteratorType) || this;
        _this._node = node;
        _this._header = header;
        if (_this.iteratorType === 0 /* IteratorType.NORMAL */) {
            _this.pre = function () {
                if (this._node === this._header._left) {
                    throwIteratorAccessError();
                }
                this._node = this._node._pre();
                return this;
            };
            _this.next = function () {
                if (this._node === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._next();
                return this;
            };
        }
        else {
            _this.pre = function () {
                if (this._node === this._header._right) {
                    throwIteratorAccessError();
                }
                this._node = this._node._next();
                return this;
            };
            _this.next = function () {
                if (this._node === this._header) {
                    throwIteratorAccessError();
                }
                this._node = this._node._pre();
                return this;
            };
        }
        return _this;
    }
    Object.defineProperty(TreeIterator.prototype, "index", {
        /**
         * @description Get the sequential index of the iterator in the tree container.<br/>
         *              <strong>Note:</strong>
         *              This function only takes effect when the specified tree container `enableIndex = true`.
         * @returns The index subscript of the node in the tree.
         * @example
         * const st = new OrderedSet([1, 2, 3], true);
         * console.log(st.begin().next().index);  // 1
         */
        get: function () {
            var _node = this._node;
            var root = this._header._parent;
            if (_node === this._header) {
                if (root) {
                    return root._subTreeSize - 1;
                }
                return 0;
            }
            var index = 0;
            if (_node._left) {
                index += _node._left._subTreeSize;
            }
            while (_node !== root) {
                var _parent = _node._parent;
                if (_node === _parent._right) {
                    index += 1;
                    if (_parent._left) {
                        index += _parent._left._subTreeSize;
                    }
                }
                _node = _parent;
            }
            return index;
        },
        enumerable: false,
        configurable: true
    });
    return TreeIterator;
}(ContainerIterator));
export default TreeIterator;
